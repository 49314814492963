.form-entry-new {
  position: relative;
}
.form-entry-new.css-var-r1b,
.form-entry-new .css-var-r1b {
  --ant-control-height: 38px;
}
.form-entry-new__label {
  font-size: 0.8rem;
  position: absolute;
  left: 0;
  top: 2px;
  transform: translate(8px, 6px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
  padding: 0 5px;
  color: #AAAAAA;
}
.form-entry-new_has-value .form-entry-new__label,
.form-entry-new_focused .form-entry-new__label {
  transform: translate(4px, -13px) scale(0.93);
  font-weight: bold;
  color: unset;
  background-color: white;
}
.form-entry-new_focused .form-entry-new__label {
  color: #4096ff;
}
.form-entry-new_validation-state_invalid .form-entry-new__label {
  color: #ff4d4f;
}
.form-entry-new_disabled .form-entry-new__label,
.form-entry-new_disabled:hover .form-entry-new__label {
  color: rgba(0, 0, 0, 0.25);
}
.form-entry-new__additional-content {
  position: absolute;
  right: 9px;
  top: -1px;
  height: 2px;
  z-index: 1;
  display: flex;
  background-color: white;
  font-size: 1rem;
  align-items: center;
  padding: 0 5px;
  gap: 10px;
}
.form-entry-new__field {
  display: flex;
  min-height: var(--ant-control-height);
}
.form-entry-new__field .ant-input,
.form-entry-new__field .ant-input-number,
.form-entry-new__field .ant-date-picker {
  height: var(--ant-control-height);
  padding: 5px 14px;
}
.form-entry-new__field .ant-input-sm,
.form-entry-new__field .ant-input-number-sm,
.form-entry-new__field .ant-date-picker-sm {
  --ant-control-height: 24px;
  padding: 0;
}
.form-entry-new__field .ant-input:focus,
.form-entry-new__field .ant-input-number:focus,
.form-entry-new__field .ant-date-picker:focus {
  box-shadow: none;
}
.form-entry-new_validation-state_invalid .form-entry-new__field .ant-input,
.form-entry-new_validation-state_invalid .form-entry-new__field .ant-input-number,
.form-entry-new_validation-state_invalid .form-entry-new__field .ant-date-picker {
  border-color: #ff4d4f;
}
.form-entry-new_disabled .form-entry-new__field .ant-input,
.form-entry-new_disabled .form-entry-new__field .ant-input-number,
.form-entry-new_disabled .form-entry-new__field .ant-date-picker {
  background: unset;
}
.form-entry-new:hover .form-entry-new__field .ant-input,
.form-entry-new:hover .form-entry-new__field .ant-input-number,
.form-entry-new:hover .form-entry-new__field .ant-date-picker {
  border-color: rgba(0, 0, 0, 0.88);
}
.form-entry-new_focused:hover .form-entry-new__field .ant-input,
.form-entry-new_focused:hover .form-entry-new__field .ant-input-number,
.form-entry-new_focused:hover .form-entry-new__field .ant-date-picker {
  border-color: #4096ff;
}
.form-entry-new__field .ant-input[readonly],
.form-entry-new__field .ant-input-number[readonly],
.form-entry-new__field .ant-date-picker[readonly] {
  background: unset;
  cursor: default;
}
.form-entry-new__field .ant-input[readonly]:focus,
.form-entry-new__field .ant-input-number[readonly]:focus,
.form-entry-new__field .ant-date-picker[readonly]:focus,
.form-entry-new__field .ant-input[readonly]:hover,
.form-entry-new__field .ant-input-number[readonly]:hover,
.form-entry-new__field .ant-date-picker[readonly]:hover {
  border-color: #d9d9d9;
}
.form-entry-new__field .ant-input-number-focused {
  box-shadow: none;
}
.form-entry-new__field .ant-switch {
  align-self: center;
  flex: 0 0 auto;
}
.form-entry-new__field input.ant-input {
  height: 38px;
}
.form-entry-new__field .text-area-auto-size {
  padding: 7px 14px;
}
.form-entry-new_focused .form-entry-new__field .text-area-auto-size {
  border-color: #4096ff;
}
.form-entry-new:hover .form-entry-new__field .text-area-auto-size {
  border-color: rgba(0, 0, 0, 0.88);
}
.form-entry-new_focused:hover .form-entry-new__field .text-area-auto-size {
  border-color: #4096ff;
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-multiple:not(.ant-pagination-size-changer) .ant-select-selector {
  height: fit-content;
  padding: 5px 14px;
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-multiple:not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.88);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-multiple:not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  box-shadow: none;
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-multiple:not(.ant-pagination-size-changer).ant-select-focused:hover .ant-select-selector {
  border-color: #4096ff;
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-single {
  height: var(--ant-control-height);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-single .ant-select-selector {
  padding: 2px 14px;
  height: var(--ant-control-height);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-single .ant-select-selector .ant-select-selection-search-input {
  height: var(--ant-control-height);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-single.ant-select-sm {
  height: var(--ant-control-height-sm);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-single.ant-select-sm .ant-select-selector {
  padding: 2px 14px;
  height: var(--ant-control-height-sm);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-single.ant-select-sm .ant-select-selector .ant-select-selection-search-input {
  height: var(--ant-control-height-sm);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select:not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.88);
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select:not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector {
  box-shadow: none;
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select:not(.ant-pagination-size-changer).ant-select-focused:hover .ant-select-selector {
  border-color: #4096ff;
}
.form-entry-new__field .ant-select:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector {
  background-color: unset;
}
.form-entry-new__field .ant-radio-group {
  padding-top: 14px;
  padding-bottom: 6px;
}
.form-entry-new__field .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}
.form-entry-new__field > :first-child {
  flex: 1 1 auto;
}
.form-entry-new__field .drop-down-list {
  height: var(--ant-control-height);
}
.form-entry-new__field .drop-down-list .k-input-button {
  height: var(--ant-control-height);
}
.form-entry-new__validation-message {
  color: #ff4d4f;
  font-size: 0.75rem;
  margin: 0 0 0 14px;
}
.section_appearance_frame > .section__content > .form-entry-new:first-child {
  margin-top: 10px;
}
